import React, { memo } from 'react';
import FieldGroup from './field_group';

import './password_field.sass';

const PasswordField = memo((props) => {
  return (
    <FieldGroup
      {...props}
      render={({ required, ...otherProps }) =>
        <input
          type="password"
          className="c-password-field__input"
          aria-required={required}
          {...otherProps}
        />
      }
    />
  );
});

PasswordField.propTypes = {
  ...FieldGroup.propTypes
};

PasswordField.displayName = 'PasswordField';
export default PasswordField;
