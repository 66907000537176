import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeClassName, uniqueId } from 'utils';
import FieldErrorHandler from "./field_error_handler";
import { InlineFormLabel } from 'form/inline_form_label';
import { InvisibleInput } from 'form/invisible_input';
import { RadioWidget } from 'controls/radio_widget';

import './radio_set.sass';

const RadioContext = React.createContext(() => ({}));

/**
 * Radio set component. Behaves like a radio button, with one selected option at a time.
 *
 * <Form.RadioSet
 *   handleChange={(name, val) => this.setState({selectedRadio: val})}
 *   name="myradio"
 *   value="one"
 * >
 *   <Form.Radio
 *     headLabel="Item One"
 *     label="This is the first item"
 *     value="one"
 *   />
 *   <Form.Radio
 *     headLabel="Item Two"
 *     label="This is the second item"
 *     value="two"
 *   />
 * </Form.RadioSet>
 *
 * @property {function(string)} handleChange - Called when a radio option is selected.
 * @property {String} name - The shared input name of all radio buttons in the set.
 * @property {String} value - The currently selected value.
 * @property {String} className - Additional CSS class names.
 */
export const RadioSet = (props) => {
  const id = useRef(`radioset_${uniqueId()}`);

  const onClick = val => {
    props.handleChange(props.name, val);
  };

  const groupAria = { 'aria-required': props.required };
  if (props.label) {
    groupAria['aria-labelledby'] = id.current;
  } else if (props.ariaLabel) {
    groupAria['aria-label'] = props.ariaLabel;
  }

  return (
    <RadioContext.Provider value={{ click: onClick, radioSetProps: props }} >
      <FieldErrorHandler
        {...props}
        render={(hasErrors, createErrorAttributes, renderErrorText) =>
          <div
            className="c-radio-set__input_group"
            role="radiogroup"
            {...groupAria}
            {...createErrorAttributes()}
          >
            {props.label &&
              <div id={id.current}>
                {props.label}{props.required ? ' *' : ''}
              </div>}
            {props.children}
            {hasErrors && renderErrorText()}
          </div>
        }
      />
    </RadioContext.Provider>
  );
};

/**
 * A radio button. This must be contained in a RadioSet.
 *
 * @property {String} headLabel - A header for the label.
 * @property {String} label - A label.
 * @property {String} value - The value of this radio button. It will be passed into the onChange
 *   property of RadioSet when this radio is selected.
 */
export const Radio = (props) => {
  const id = props.id || `radio_${uniqueId()}`;

  return (
    <RadioContext.Consumer>
      {({ click, radioSetProps }) => {
        if (!radioSetProps) {
          throw (new Error('Attempted to create a Radio outside of RadioSet.'));
        }

        const className =
          makeClassName("c-radio__wrapper",
            `c-radio__wrapper--${props.appearance}`,
            `c-radio__wrapper--${props.appearance}-${radioSetProps.value === props.value}`,
            props.className);

        return (
          <div className={className}>
            <div className="c-radio__widget-wrapper">
              <InvisibleInput
                type="radio"
                id={id}
                name={radioSetProps.name}
                value={props.value}
                checked={radioSetProps.value === props.value}
                onChange={() => click(props.value)}
                disabled={props.disabled}
                className="c-radio__input"
              />
              <RadioWidget
                selected={radioSetProps.value === props.value}
                disabled={props.disabled}
              />
            </div>
            <InlineFormLabel
              label={props.label}
              htmlFor={id}
              headLabel={props.headLabel}
            />
          </div>);
      }}
    </RadioContext.Consumer>
  );
};

RadioSet.propTypes = {
  ...FieldErrorHandler.propTypes,
  className: PropTypes.string
};

Radio.propTypes = {
  appearance: PropTypes.oneOf([
    'long-button-style',
    'button-style',
    'radio-style'
  ]),
  headLabel: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]),
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

Radio.defaultProps = {
  disabled: false,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired
};
