import React from "react";
import PropTypes from "prop-types";
import { makeClassName, uniqueId } from 'utils';
import FieldErrorHandler from './field_error_handler';
import { InlineFormLabel } from 'form/inline_form_label';
import { InvisibleInput } from 'form/invisible_input';
import { CheckboxWidget } from 'controls/checkbox_widget';

import './checkbox.sass';

const Checkbox = (props) => {
  /**
   * Some user-facing checkbox phrasing inverts/contradicts the computer state
   * (e.g. 'make my profile private' when the actual record refers to `profile_public`).
   * This function ensures that the computer state (the value) is flipped in the checkbox
   * to conform to what the user is directed to expect.
   */
  const conditionallyInvertValue = () => {
    return props.valueInvertedByText
      ? !props.value
      : !!props.value; // Force to boolean
  };

  const { onlyGroupErrors } = props;

  const toggleCheckboxChange = () => {
    const newValue = !props.value;
    props.handleChange(props.name, newValue);
  };

  const validate = (name, val) => {
    if (props.validate) {
      return props.validate(name, val);
    } else {
      return props.required && !val ? ['is required'] : [];
    }
  };

  const checkboxId = props.id || `checkbox_${uniqueId()}`;

  const className =
    makeClassName("cr-checkbox__wrapper",
      `cr-checkbox__wrapper--${props.appearance}`,
      `cr-checkbox__wrapper--${props.appearance}-${props.value}`,
      props.className);

  return (
    <FieldErrorHandler
      {...props}
      validate={validate}
      errorLabel={props.errorLabel}
      render={(hasErrors, createErrorAttributes, renderErrorText) => (
        <div>
          <div className={className}>
            <div className="cr-checkbox__widget-wrapper">
              <InvisibleInput
                type="checkbox"
                id={checkboxId}
                role="option"
                value={conditionallyInvertValue()}
                name={props.name}
                disabled={props.disabled}
                onChange={toggleCheckboxChange}
                checked={conditionallyInvertValue()}
                className="cr-checkbox__input"
                {...createErrorAttributes()}
              />
              <CheckboxWidget
                checked={conditionallyInvertValue()}
                hasError={hasErrors}
                disabled={props.disabled}
                className="cr-checkbox__checkbox-widget"
              />
            </div>
            {props.label &&
              <InlineFormLabel
                label={props.label}
                htmlFor={checkboxId}
                headLabel={props.headLabel}
                hasError={hasErrors}
                screenReaderLabel={props.screenReaderLabel}
              />
            }
          </div>
          {hasErrors &&
            <div className="checkbox__errors">
              {!onlyGroupErrors && renderErrorText()}
            </div>
          }
        </div>
      )}
    />
  );
};

Checkbox.propTypes = {
  ...FieldErrorHandler.propTypes,
  className: PropTypes.string,
  /**
   * True if the checkbox should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Passed to FieldErrorHandler when label is not just a string
   * Useful if label contains links
   */
  errorLabel: PropTypes.string,
  /**
   * When the value of the checkbox is changed
   */
  handleChange: PropTypes.func.isRequired,
  /**
   * The text that is displayed alongside the checkbox
   */
  label: PropTypes.node,
  /**
   * The name of the checkbox
   */
  name: PropTypes.string,
  /**
   * The checkbox must be checked to submit the form
   */
  required: PropTypes.bool,
  /**
   * Some user-facing checkbox phrasing inverts/contradicts the computer state
   * (e.g. 'make my profile private' when the actual record refers to `profile_public`).
   * This function ensures that the computer state (the value) is flipped in the checkbox
   * to conform to what the user is directed to expect.
   *
   */
  valueInvertedByText: PropTypes.bool,
  /**
   * The value of the checkbox
   */
  // TODO: This isn't how checkboxes work. Value is an arbitrary string, and checked determines
  //      whether that string is sent to the server.

  value: PropTypes.bool,
  /**
   * turns off error text for individual checkboxes and only shows it in the group
   */
  onlyGroupErrors: PropTypes.bool,
  /**
   * Not supported yet
   */
  tabIndex: PropTypes.number,
  /**
   * Hides the label text accessibly for screen readers
   */
  screenReaderLabel: PropTypes.bool
};

Checkbox.defaultProps = {
  valueInvertedByText: false
};

export default Checkbox;
