import React from "react";
import { Select } from "./select";
import { useIntl } from "react-intl";

export const BirthYearDropdown = props =>
  <Select
    {...props}
    placeholder={useIntl().formatMessage({
      id: "settings.profile.choose_year",
      defaultMessage: "Choose Year"
    })}
    options={getBirthYearsOptions()}
  />;

/**
 * The minimum year that can appear in the birth year dropdown.
 *
 * @type {number}
 */
BirthYearDropdown.MIN_YEAR = 1900;

BirthYearDropdown.propTypes = {
  ...Select.propTypes
};

BirthYearDropdown.defaultProps = {
  ...Select.defaultProps,
  label: "Birth Year"
};

let globalYearsOptions;
const doNotDiscloseOption = { selectionValue: '', displayValue: 'Do not disclose' };

function DoNotDiscloseFormattedMessage() {
  return useIntl().formatMessage({
    id: "settings.profile.do_not_disclose",
    defaultMessage: "Do not disclose"
  });
}

function generateBirthYearsArray() {
  const mostRecentYear = new Date(Date.now()).getFullYear();
  const years = [];
  for (let i = mostRecentYear; i >= BirthYearDropdown.MIN_YEAR; i--) {
    years.push(i);
  }
  return years;
};

function getBirthYearsOptions() {
  if (!globalYearsOptions) {
    const years = generateBirthYearsArray();

    globalYearsOptions = years.map(item => ({
      displayValue: item,
      selectionValue: item
    }));

    const doNotDiscloseOption = {
      selectionValue: '',
      displayValue: DoNotDiscloseFormattedMessage()
    };
    globalYearsOptions.unshift(doNotDiscloseOption);
  }

  return globalYearsOptions;
};

export const testing = { generateBirthYearsArray, getBirthYearsOptions, doNotDiscloseOption };
