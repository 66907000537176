import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeClassName, uniqueId } from 'utils';
import { useDeepEquals, useSortedArray } from 'utils/react_utils';
import Checkbox from './checkbox';
import FieldErrorHandler from './field_error_handler';

import './checkbox_group.sass';

/**
 * Shows a list of options as a series of checkboxes, which are checked if the corresponding option
 * is present in the value array. Takes all of the standard "propsForFieldGroup" props, along with
 * the aforementioned "options" prop.
 *
 * @param {Object} props
 *   @param {{ displayValue:string, selectionValue:string }[]} props.options - list of possible
 *     options, which will be rendered as checkboxes
 *   @param {string[]} props.value - the current value of the attribute, as an array of values
 *     corresponding to selectionValue from options
 *   @param {string} props.className - optional class name to add to the wrapper element
 *   @param {string} props.name - the name of the attribute this group of checkboxes corresponds to
 *   @param {Function(string, string[])} props.handleChange - called when the value of the set of
 *     checkboxes should change; this is expected to trigger a corresponding update to props.value
 *   @param {string} props.label - the label to display for the group of checkboxes (individual
 *     checkbox labels are determined by their option's displayValue)
 *   @param {boolean} [props.required] - if true, users must check a least one of the boxes
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxGroup = (props) => {
  const options = useDeepEquals(props.options);
  const [value, addValue, removeValue, hasValue] = useSortedArray(props.value || []);
  const namePrefix = useRef();
  if (typeof namePrefix.current !== 'number') {
    namePrefix.current = uniqueId();
  }

  const handleChange = useCallback((name, checked) => {
    const checkboxValue = name.replace(`${namePrefix.current}_`, '');
    let newValue;
    if (checked) {
      newValue = addValue(checkboxValue);
    } else {
      newValue = removeValue(checkboxValue);
    }
    if (newValue !== value) {
      props.handleChange(props.name, newValue);
    }
  }, [value, props.handleChange, props.name]);

  useEffect(() => {
    const toRemove = value.filter((valueItem) => (
      !options.find((e) => e.selectionValue === valueItem)
    ));
    const newValue = removeValue(...toRemove);
    if (newValue !== value) {
      props.handleChange(props.name, newValue);
    }
  }, [options, props.name]);

  const validate = useCallback((name, value) => {
    const errors = [];
    if (props.required && value.length === 0) {
      errors.push('is required');
    }
    return [...errors, ...((props.validate && props.validate(name, value)) || [])];
  }, [props.validate, props.required, value]);

  const descriptionId = `description_${namePrefix.current}`;

  return (
    <FieldErrorHandler
      {...props}
      noPadding
      validate={validate}
      render={(hasErrors, createErrorAttributes, renderErrorText) => (
        <div
          className={makeClassName('checkbox-group', hasErrors && 'checkbox-group--errors')}
          role="listbox"
          aria-labelledby={descriptionId}
          {...createErrorAttributes()}
          aria-required={props.required}
        >
          <div className="checkbox-group__label" id={descriptionId}>
            {props.label}{props.required ? ' *' : ''}
          </div>
          {props.subheading && (
            <div className="checkbox-group__subheading">
              {props.subheading}
            </div>
          )}
          <div className={makeClassName(
            'checkbox-group__input-group',
            props.appearance && `checkbox-group__input-group--${props.appearance}`,
            props.className)}
          >
            {options.map((item) => (
              <Checkbox
                {...props}
                className="checkbox-group__checkbox"
                handleChange={handleChange}
                name={`${namePrefix.current}_${item.selectionValue}`}
                value={hasValue(item.selectionValue)}
                key={item.selectionValue}
                label={item.displayValue}
                claimField={() => null}
                releaseField={() => null}
                onlyGroupErrors
              />
            ))}
            {hasErrors && renderErrorText()}
          </div>
        </div>
      )}
    />
  );
};

CheckboxGroup.propTypes = {
  ...FieldErrorHandler.propTypes,
  appearance: PropTypes.oneOf([
    'button-style',
    'checkbox-style'
  ]),
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
  subheading: PropTypes.node,
  className: PropTypes.string
};
