import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';

import { makeClassName } from 'utils/index';

import './action_indicator.sass';

/**
 * An icon based on action state. This component is built to work with redux-resource's state
 * object.
 *
 * @property {Boolean} pending - The request is pending.
 * @property {Boolean} succeeded - The request succeeded.
 * @property {Boolean} failed - The request failed.
 * @property {Boolean} strictShow - If the appropriate status is available, always show the
 *   indicator. When this is off (the default), the behavior is to always show when props.pending
 *   is true, and otherwise only to show when the status changes -- not on initial page load or
 *   on re-render.
 * @property {Boolean} persist - When true, the action indicator does not fade out after status change
 */
export default class ActionIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = { lastStatus: '', changed: false };
  }

  /**
   * Before rendering, check whether the status changed.
   *
   * @param {Object} props
   * @param {Object} state
   * @returns {Object} the modified state.
   */
  static getDerivedStateFromProps(props, state) {
    let status;
    if (props.pending) {
      status = 'pending';
    } else if (props.succeeded) {
      status = 'succeeded';
    } else if (props.failed) {
      status = 'failed';
    } else {
      status = 'idle';
    }

    const changed = state.lastStatus && state.lastStatus !== status;
    return {
      lastStatus: status,
      changed: state.changed || changed
    };
  }

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    const messages = { ...this.defaultProps, ...this.props.messages };

    if (this.props.pending) {
      return ActionIndicator.Icon(
        'pending',
        messages.pending,
        faSpinner,
        true,
        this.props.suppressAlert
      );
    }

    if (this.state.changed || this.props.strictShow) {
      if (this.props.succeeded) {
        return ActionIndicator.Icon(
          'succeeded',
          messages.succeeded,
          faCheck,
          this.props.persist,
          this.props.suppressAlert
        );
      }

      if (this.props.failed) {
        return ActionIndicator.Icon(
          'failed',
          messages.failed,
          faExclamation,
          this.props.persist,
          this.props.suppressAlert
        );
      }
    }

    return <Fragment/>;
  }
}

ActionIndicator.Icon = (status, ariaLabelText, icon, persist, suppressAlert) => {
  return (
    <span
      className={makeClassName(
        `action-indicator action-indicator--${status}`,
        !persist && 'action-indicator--fadeout'
      )}
      aria-label={ariaLabelText}
      {...(suppressAlert ? {} : {
        role: 'alert',
        'aria-live': 'assertive',
        'aria-atomic': true
      })}
    >
      <FontAwesomeIcon icon={icon}/>
    </span>
  );
};


ActionIndicator.defaultProps = {
  messages: {
    pending: 'Loading',
    succeeded: 'Success',
    failed: 'Failure'
  },
  suppressAlert: false
};

ActionIndicator.propTypes = {
  /**
   pending=true overrides succeeded or failed,
  */
  pending: PropTypes.bool,
  succeeded: PropTypes.bool,
  failed: PropTypes.bool,
  strictShow: PropTypes.bool,
  persist: PropTypes.bool,
  messages: PropTypes.shape({
    pending: PropTypes.string,
    succeeded: PropTypes.string,
    failed: PropTypes.string
  }),
  suppressAlert: PropTypes.bool
};
