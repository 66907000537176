import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './inline_form_label.sass';

/**
 * Label used for inline form components such radio buttons or checkboxes
 *
 * @param {object} props
 *   @param {string|JSX.Element} [props.label] - the rendered value for the label
 *   @param {string} [props.headLabel] - a heading label positioned above the label and bolded
 *   @param {string} [props.htmlFor] - the HTML element for this label
 *   @param {boolean} [props.disabled] - reduces the opacity of the text
 *   @param {boolean} [props.hasError] - displays red error text
 *   @param {boolean} [props.screenReaderLabel] - hides the label accessibly for screen readers
 * @returns {JSX.Element}
 * @constructor
 */
export const InlineFormLabel = (props) => {
  return (
    <label
      className={makeClassName(
        "c-inline-form-label__label-wrapper",
        props.disabled && "c-inline-form-label--disabled",
        props.hasError && "c-inline-form-label--error",
        props.screenReaderLabel && "sr-only"
      )}
      htmlFor={props.htmlFor}
    >
      {props.headLabel && <div className="c-inline-form-label__head-label">{props.headLabel}</div>}
      <div>{props.label}</div>
    </label>
  );
};

InlineFormLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  htmlFor: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  screenReaderLabel: PropTypes.bool
};
