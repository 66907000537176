import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeClassName, uniqueId } from 'utils';
import * as objUtils from 'utils/object';
import { MONTHS } from 'utils/date';
import { Validators } from 'utils/validators';
import FieldErrorHandler from './field_error_handler';
import { Select } from './select';
import TextField from './text_field';
import {
  injectIntl,
  FormattedMessage,
  defineMessages
} from 'react-intl';
import { intlKeyFromValue } from 'translations';

import './compound_date_field.sass';

/**
 * Provides inputs for entering a date including day, month, and year.  Exposes the chosen value
 * to the containing form in the format YYYY-MM-DD; however, note that any or all parts of that
 * may be empty, if the user has not entered a value for that field.
 *
 * @property {function(name:string, iso8601Date:string)} handleChange - a function to report value
 *   changes to containing form, takes name of field and value
 * @property {string} [errorLabel] - a value with which to prefix inline errors displayed for this
 *   field; defaults to labelPrefix if not provided
 * @property {string} labelPrefix - a prefix to use for individual date fields; will be combined
 *   with a suffix for the individual field, for example "Birth Day", where "Birth" is the
 *   labelPrefix; since the day and year fields are fairly narrow, this must be kept short
 * @property {string} name - the name of the field
 * @property {string} value - the current date represented by the fields; in the form YYYY-MM-DD
 */
export class CompoundDateField extends Component {
  constructor(props) {
    super(props);
    this.fieldNameSuffix = uniqueId();
  }

  /**
   * Generate a label for an individual sub-field, given the name of the part {day, month, year}.
   * @param {string} part - sub-field for which to generate the label value
   * @returns {string}
   */
  fieldLabel(part) {
    let labelPrefix = '';
    if (this.props.labelPrefix) {
      const trimmedLabelPrefix = `${this.props.labelPrefix.trim()}`;
      labelPrefix = (
        <FormattedMessage
          id={intlKeyFromValue(trimmedLabelPrefix, 'compound_date')}
          defaultMessage={trimmedLabelPrefix}
        />
      );
    }

    switch (part) {
      case 'day':
        return (
          <FormattedMessage
            id="compound_date.day"
            defaultMessage="{labelPrefix} Day"
            values={{ labelPrefix: labelPrefix }}
          />
        );
      case 'month':
        return (
          <FormattedMessage
            id="compound_date.month"
            defaultMessage="{labelPrefix} Month"
            values={{ labelPrefix: labelPrefix }}
          />
        );
      case 'year':
        return (
          <FormattedMessage
            id="compound_date.year"
            defaultMessage="{labelPrefix} Year"
            values={{ labelPrefix: labelPrefix }}
          />
        );
    }
  }

  /**
   * Normalize a sub-field value by removing any leading zeroes.
   *
   * @param {string} value - the sub-field value
   * @returns {string}
   */
  static normalizePart(value) {
    return (value || '').replace(/^0*/, '');
  }

  /**
   * Function to report change in day sub-field.
   * @param {string} _name - ignored; full name of sub-field
   * @param {string} value - the curent value of the sub-field
   */
  onDayChange = (_name, value) => {
    if (this.constructor.validDay(value)) {
      this.triggerChange({ day: value });
    }
  };

  /**
   * Function to report change in month sub-field.
   * @param {string} _name - ignored; full name of sub-field
   * @param {string} value - the curent value of the sub-field
   */
  onMonthChange = (_name, value) => {
    if (!value || this.constructor.validMonth(value)) {
      this.triggerChange({ month: value });
    }
  };

  /**
   * Function to report change in year sub-field.
   * @param {string} _name - ignored; full name of sub-field
   * @param {string} value - the curent value of the sub-field
   */
  onYearChange = (_name, value) => {
    if (this.constructor.validYear(value)) {
      this.triggerChange({ year: value });
    }
  };

  /**
   * Parse the input value into its constituent parts.
   * @returns {{year: string, month: string, day: string}}
   */
  static parseAsDate(value) {
    const DATE_REGEX = /^(\d{0,4})-(\d{0,2})-(\d{0,2})$/;
    const parts = DATE_REGEX.exec(value) || [];
    return {
      year: parts[1] || '',
      month: this.normalizePart(parts[2] || ''),
      day: parts[3] || ''
    };
  }

  /**
   * Parse the prop value into its constituent parts using CompoundDateField.parseAsDate
   * @returns {{year: string, month: string, day: string}}
   */
  parseDate() {
    return this.constructor.parseAsDate(this.props.value);
  }

  /**
   * Report a change to the parent component using the handleChange prop.
   * @param {Object} newProps - an object containing new values for day, month, and/or year
   *   sub-fields; any date parts not present will retain their previous value
   */
  triggerChange(newProps) {
    const value = { ...this.parseDate(), ...newProps };
    let formattedValue;
    if (value.year || value.month || value.day) {
      formattedValue = `${value.year}-${value.month}-${value.day}`;
    } else {
      formattedValue = '';
    }

    this.props.handleChange(this.props.name, formattedValue);
  }

  /**
   * Get a unique name for the given date part sub-field
   * @param {string} fieldPart - the date part; one of {day, month, year}
   * @returns {string}
   */
  uniqueFieldName(fieldPart) {
    return `${this.props.name}_${fieldPart}_${this.fieldNameSuffix}`;
  }

  /**
   * Validates the value of the field, which includes checking that all date parts are present and
   * that the value is possible (e.g. not April 31st nor February 29th except in leap years, etc.)
   * @param {string} name - the name of the CompoundDateField, passed into any custom validation
   *   function provided as a prop to this component
   * @param {string} value - the current value of the CompoundDateField
   * @returns {Array} - an array containing errors found (empty if field is syntactically valid)
   */
  validate = (name, value) => {
    const errors = [];
    const valueIsPresent = value && !/^\s*$/.test(value);

    const intl = this.props.intl;

    const messages = defineMessages({
      dayRequired: {
        id: 'send_transcript.birth.day_is_required',
        defaultMessage: 'day is required:'
      },
      monthRequired: {
        id: 'send_transcript.birth.month_is_required',
        defaultMessage: 'month is required'
      },
      invalidMonth: {
        id: 'send_transcript.birth.month_is_invalid',
        defaultMessage: 'month is invalid'
      },
      yearRequired: {
        id: 'send_transcript.birth.year_is_required',
        defaultMessage: 'year is required'
      },
      yearFourDigits: {
        id: 'send_transcript.birth.year_four_digits',
        defaultMessage: 'year must have four digits'
      },
      invalidDate: {
        id: 'send_transcript.birth.is_not_a_valid_date',
        defaultMessage: 'is not a valid date'
      },
      isRequired: {
        id: 'send_transcript.birth.is_required',
        defaultMessage: 'is required'
      },
      dayBetween: {
        id: 'send_transcript.birth.day_between',
        defaultMessage: 'day must be between 1 and 31'
      }
    });

    if (valueIsPresent) {
      const parts = this.constructor.parseAsDate(value);
      if (!parts.day) {
        errors.push(intl.formatMessage(messages.dayRequired));
      } else if (!this.constructor.validDay(parts.day)) {
        errors.push(intl.formatMessage(messages.dayBetween));
      }
      if (!parts.month) {
        errors.push(intl.formatMessage(messages.monthRequired));
      } else if (!this.constructor.validMonth(parts.month)) {
        // since this is a dropdown, there shouldn't really be a way to get here, which is why
        // there's no more specific error message to display
        errors.push(intl.formatMessage(messages.invalidMonth));
      }
      if (!parts.year) {
        errors.push(intl.formatMessage(messages.yearRequired));
      } else if (!/^\d{4}$/.test(parts.year)) {
        errors.push(intl.formatMessage(messages.yearFourDigits));
      }
      if (errors.length === 0 && !Validators.iso8601Date(value)) {
        errors.push(intl.formatMessage(messages.invalidDate));
      }
    } else if (this.props.required) {
      errors.push(intl.formatMessage(messages.isRequired));
    }
    if (errors.length === 0) {
      return this.props.validate ? this.props.validate(name, value) : [];
    } else {
      return errors;
    }
  };

  /**
   * Returns true if value is a possible value for the day date part.
   * @param {string} value - the prospective value to validate
   * @returns {boolean}
   */
  static validDay(value) {
    // parseInt will happily parse a valid prefix of a value that we do not want to consider valid,
    // so this check just ensures that the syntax of the input is correct
    if (/^\d{0,2}$/.test(value)) {
      const intVal = parseInt(value);
      // allow users to delete value before entering a new one, and to start day with "0"
      return value === '' || value === '0' || (intVal > 0 && intVal < 32);
    }
    return false;
  }

  /**
   * Returns true if value is a possible value for the month date part.
   * @param {string} value - the prospective value to validate
   * @returns {boolean}
   */
  static validMonth(value) {
    // parseInt will happily parse a valid prefix of a value that we do not want to consider valid,
    // so this check just ensures that the syntax of the input is correct
    if (/^\d{1,2}$/.test(value)) {
      const intVal = parseInt(value);
      return intVal > 0 && intVal < 13;
    }
    return false;
  }

  /**
   * Returns true if value is a possible value for the year date part.
   * @param {string} value - the prospective value to validate
   * @returns {boolean}
   */
  static validYear(value) {
    // Allow exactly empty, in case user makes a typo and tries to delete it, otherwise it should
    // be some digits
    return value === '' || /^(\d{1,4})$/.test(value);
  }


  MONTH_OPTIONS = MONTHS.map((displayValue, selectionValue) => {
    const intl = this.props.intl;
    return {
      displayValue: intl.formatMessage({
        id: intlKeyFromValue(displayValue, 'month')
      }),
      selectionValue: (selectionValue + 1).toString()
    };
  });

  /**
   * Render the component.
   * @returns {React.element}
   */
  render() {
    const commonFieldProps = {
      enclosed: this.props.enclosed,
      required: this.props.required
    };

    const dateParts = this.parseDate();
    return (
      <FieldErrorHandler
        name={this.props.name}
        errors={this.props.errors}
        label={this.props.errorLabel || this.props.labelPrefix}
        claimField={this.props.claimField}
        validate={this.validate}
        releaseField={this.props.releaseField}
        render={(hasErrors, createErrorAttributes, renderErrorText) => {
          return (
            <div
              className={makeClassName(
                'cr-compound-date-field',
                'row',
                this.props.className
              )}
            >
              <TextField
                className="cr-compound-date-field__day-part col-sm-3"
                id={this.uniqueFieldName('day')}
                name={this.uniqueFieldName('day')}
                value={dateParts.day}
                hasErrors={hasErrors}
                label={this.fieldLabel('day')}
                handleChange={this.onDayChange}
                placeholder={this.props.intl.formatMessage({
                  id: 'compound_date.dd',
                  defaultMessage: 'DD'
                })}
                {...commonFieldProps}
                {...createErrorAttributes()}
              />
              <FormattedMessage
                id="compound_date.select_month"
                defaultMessage="Select Month"
              >
                {(placeholder) => (
                  <Select
                    className="cr-compound-date-field__month-part col-sm-6"
                    id={this.uniqueFieldName('month')}
                    name={this.uniqueFieldName('month')}
                    value={dateParts.month}
                    hasErrors={hasErrors}
                    options={this.MONTH_OPTIONS}
                    resetOption=""
                    placeholder={placeholder}
                    label={this.fieldLabel('month')}
                    handleChange={this.onMonthChange}
                    {...commonFieldProps}
                    {...createErrorAttributes()}
                  />
                )}
              </FormattedMessage>
              <TextField
                className="cr-compound-date-field__year-part col-sm-3"
                id={this.uniqueFieldName('year')}
                name={this.uniqueFieldName('year')}
                value={dateParts.year}
                hasErrors={hasErrors}
                label={this.fieldLabel('year')}
                handleChange={this.onYearChange}
                placeholder={this.props.intl.formatMessage({
                  id: 'compound_date.yyyy',
                  defaultMessage: 'YYYY'
                })}
                {...commonFieldProps}
                {...createErrorAttributes()}
              />
              {hasErrors && renderErrorText()}
            </div>
          );
        }}
      />
    );
  }
}

CompoundDateField.propTypes = {
  ...objUtils.except(FieldErrorHandler.propTypes, ['label']),
  errorLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleChange: PropTypes.func.isRequired,
  labelPrefix: PropTypes.string
};

// eslint-disable-next-line no-class-assign
CompoundDateField = injectIntl(CompoundDateField);
