import React from 'react';
import { Button } from 'controls/button';

/**
 * A form wrapper around the <Button> element.
 *
 * @see Button
 */
const Submit = props => (
  <Button {...props} isSubmit>{props.children}</Button>
);

Submit.propTypes = Button.propTypes;
Submit.defaultProps = Button.defaultProps;

export default Submit;
