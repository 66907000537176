import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { useDeepEquals, useSortedArray } from 'utils/react_utils';
import Checkbox from './checkbox';
import FieldErrorHandler from './field_error_handler';

import './checkbox_single.sass';

/**
 * Creates an array of values that have been 'checked' by the user
 * Shows a single option as a checkbox, which are checked if the corresponding option
 * is present in the value array. Takes all of the standard "propsForFieldGroup" props, along with
 * the aforementioned "options" prop.
 *
 * @param {Object} props
 *   @param {{ displayValue:string, selectionValue:string }[]} props.options - list of possible
 *     options, which will be rendered as checkboxes
 *   @param {string[]} props.value - the current value of the attribute, as an array of values
 *     corresponding to selectionValue from options
 *   @param {string} props.name - the name of the attribute this group of values corresponds to
 *   @param {Function(string, string[])} props.handleChange - called when the value of the set of
 *     checkboxes should change; this is expected to trigger a corresponding update to props.value
 *   @param {string} props.label - the label to display for the group of checkboxes (individual
 *     checkbox labels are determined by their option's displayValue)
 *   @param {boolean} [props.required] - if true, users must check a least one of the boxes
 * @returns {JSX.Element}
 * @constructor
 */

export const CheckboxSingle = (props) => {
  const options = useDeepEquals(props.options);
  const [value, addValue, removeValue, hasValue] = useSortedArray(props.value || []);

  const handleChange = useCallback((templateId, checked) => {
    let newValue;
    if (checked) {
      newValue = addValue(templateId);
    } else {
      newValue = removeValue(templateId);
    }
    if (newValue.length !== value.length) {
      props.handleChange(props.name, newValue);
    }
  }, [value, props.handleChange, props.name]);

  const validate = useCallback((name, value) => {
    const errors = [];
    if (props.required && value.length === 0) {
      errors.push('is required');
    }
    return [...errors, ...((props.validate && props.validate(name, value)) || [])];
  }, [props.validate, props.required, value]);

  return (
    <FieldErrorHandler
      {...props}
      noPadding
      validate={validate}
      render={(hasErrors, createErrorAttributes, renderErrorText) => (
        <div
          className={makeClassName('checkbox-single', hasErrors && 'checkbox-single--errors')}
          role="single"
          {...createErrorAttributes()}
          aria-required={props.required}
        >
          <div className="checkbox-single__input-single">
            {options.map((item) => (
              <Checkbox
                className="checkbox-single__checkbox"
                handleChange={handleChange}
                name={item.selectionValue}
                value={hasValue(item.selectionValue)}
                key={item.selectionValue}
                id={item.selectionValue}
                claimField={() => null}
                releaseField={() => null}
                disabled={props.disabled}
              />
            ))}
            {hasErrors && renderErrorText()}
          </div>
        </div>
      )}
    />
  );
};

CheckboxSingle.propTypes = {
  ...FieldErrorHandler.propTypes,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool
};
