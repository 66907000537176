import React from 'react';
import { Select } from './select';

export const LanguageDropdown = props =>
  <Select
    {...props}
    required
    onChange={props.handleChange}
  />;



LanguageDropdown.propTypes = {
  ...Select.propTypes
};

LanguageDropdown.defaultProps = {
  ...Select.defaultProps,
  label: 'Language'
};
