import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import FieldErrorHandler from './field_error_handler';

/**
 * Hidden field component. This creates a controlled <input type="hidden">. The main purpose is to
 * have a place to render error messages. If that is not required, it's easier to add the required
 * key to Form.props.initialValues.
 *
 * @property {String} name - The name of the field.
 * @property {String} value - The value of the field.
 * @property {String} label - The label, used for error messages. This is not rendered.
 * @property {Array} children - Optional children are rendered.
 */
export class HiddenField extends PureComponent {
  /**
   * Disable all client-side validation, since there's no way for the user to correct errors.
   *
   * @returns {Array} an empty set of error messages.
   */
  validate() {
    return [];
  }

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    // This logic is copied logic from FieldGroup, which normally handles null/undefined values.
    const value =
      this.props.value === undefined || this.props.value === null ? '' : this.props.value;

    // Use noPadding, because the parent should be responsible for all styling.
    return (
      <FieldErrorHandler
        {...this.props}
        validate={this.validate}
        noPadding
        render={(hasErrors, createErrorAttributes, renderErrorText) =>
          <Fragment>
            {hasErrors && renderErrorText()}
            <input type="hidden" name={this.props.name} value={value}/>
            {this.props.children}
          </Fragment>
        }/>
    );
  }
}

HiddenField.propTypes = {
  ...FieldErrorHandler.propTypes,
  children: PropTypes.any
};
