import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCalendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight';
import { makeClassName } from 'utils';
import dateUtils from 'utils/date';
import elementUtils from 'utils/element';
import { HandleBlurFromExternalEvent } from 'controls/handle_blur_from_external_event';
import FieldGroup from './field_group';

import './date_field.sass';

/**
 * Renders a field for collecting a date from the user. Users can type the date directly into the
 * field in a handful of different formats ({@see parseInputDate}), or they can use the calendar
 * control to select a date.
 *
 * @param {object} props
 *   @param {function(string, string)} props.handleChange - callback to invoke when selected date
 *     is changed; calls to this function should trigger a corresponding change to props.value
 *   @param {string} props.label - the user-friendly label for this field
 *   @param {string} props.name - the name of this field
 *   @param {string} props.value - the current value of the field; behaves as a controlled component
 *     so this prop is expected to update when handleChange is called
 *   @param {string} [props.className] - an additional class name to apply to the top-level element
 *   @param {Date} [props.maxDate] - the maximum date the user can select
 *   @param {Date} [props.minDate] - the minimum date the user can select
 *   @param {Boolean} [props.fixedPosition] - one of ["top", "bottom", null]; if provided,
 *    the calendar input will always be positioned relative to the top or bottom of the input
 *    and will not be affected by scrolling or resizing of the page
 * @returns {JSX.Element}
 * @constructor
 */
export const DateField = (props) => {
  // parse the value into a Date object to use with ReactCalendar and comparisons against potential
  // new values
  const valueAsDate = useMemo(() => {
    return props.value ? dateUtils.dateFromString(props.value) : null;
  }, [props.value]);

  // memoize min and max dates based on their value, so that passing a new Date instance that
  // represents the same point in time doesn't trigger an unnecessary update
  const minDate = useRef();
  if (datesDiffer(minDate.current, props.minDate)) {
    minDate.current = props.minDate;
  }

  const maxDate = useRef();
  if (datesDiffer(maxDate.current, props.maxDate)) {
    maxDate.current = props.maxDate;
  }

  /**
   * Helper to limit selected dates to ones that fall within the minDate and maxDate boundaries.
   *
   * @param {Date|null} date - the date to adjust to fall within the specified bounds
   * @returns {Date|null}
   */
  const adjustDateWithinBounds = useCallback(
    (date) => dateUtils.boundedDate(date, minDate.current, maxDate.current),
    []
  );

  // maintains the value of the input text field, initialized from props.value and reset back to a
  // normalized form of the current value when the user presses Enter or moves focus elsewhere
  const [inputDate, setInputDate] = useState(displayDateFromValue(props.value));

  // tracks which view the ReactCalendar should be initialized with as the current active date upon
  // opening
  const [calendarActiveStartDate, setCalendarActiveStartDate] =
    useState(valueAsDate || adjustDateWithinBounds(dateUtils.today()));

  /**
   * Handler for ReactCalendar's onActiveStartDateChange event, since that field operates as a
   * controlled component, we just keep track of that date for the sub-component.
   *
   * @type {function({activeStartDate: Date}): void}
   */
  const handleActiveCalendarStartDateChange = useCallback(({ activeStartDate }) => {
    setCalendarActiveStartDate(activeStartDate);
  }, []);

  // tracks whether the calendar view should be visible or not
  const [calendarOpen, setCalendarOpen] = useState(false);

  /**
   * Handles update to the value of the date field by notifying the parent component of the new
   * value (if indeed there has been a change) and closing the calendar view, if it's open.
   *
   * @type {function(Date):void}
   */
  const handleChange = useCallback((newValue) => {
    if (datesDiffer(valueAsDate, newValue)) {
      props.handleChange(props.name, (newValue && dateUtils.formatShort(newValue)) || '');
    }
  }, [props.handleChange, props.name, valueAsDate]);

  /**
   * Handler for updating the value of the field from user text input. Notifies parent component of
   * change (via setDate), as well as updating the displayed date value, so as to normalize input
   * that may have been entered in a different format, even if it matches the already-selected
   * value.
   *
   * @type {function(string):void}
   */
  const commitDate = useCallback((inputDate) => {
    const parsed = adjustDateWithinBounds(parseInputDate(inputDate));
    handleChange(parsed);
    setInputDate(displayDateFromValue(parsed));
    setCalendarOpen(false);
  }, [adjustDateWithinBounds, handleChange]);

  /**
   * Version of commitDate that wraps the current value of the inputDate state variable as the text
   * input to commit.
   *
   * @type {function}
   */
  const commitInputDate = useCallback(() => { commitDate(inputDate); }, [commitDate, inputDate]);

  // update internal state based on changes to props.value, so that the input text field accurately
  // reflects the current value
  useEffect(() => {
    const displayDate = displayDateFromValue(props.value);
    commitDate(displayDate);
  }, [props.value, commitDate]);

  /**
   * Handler for date input change to update inputDate state variable, since that element is a
   * controlled input.
   *
   * @param {Event} evt - the DOM event that triggered the change
   */
  const handleInputChange = useCallback((evt) => {
    evt.preventDefault();
    setInputDate(evt.target.value);
  }, []);

  // reference to the input group containing the text field and the calendar, used to determine
  // appropriate orientation for the calendar, when that is visible
  const container = useRef();

  /**
   * Handler for when input group (including both the text field and calendar) loses focus. Causes
   * any changes to the input field to be "committed" (interpreted as a date and signaled to
   * parent).
   *
   * @type {function():void}
   */
  const handleBlur = useCallback(() => {
    commitInputDate();
    setCalendarOpen(false);
  }, [commitInputDate]);

  /**
   * Handler for when component receives focus; causes calendar to open.
   *
   * @type {function():void}
   */
  const handleFocus = useCallback(() => { setCalendarOpen(true); }, []);

  // reference to the input text field, used for determining appropriate orientation of calendar
  // control
  const inputRef = useRef();

  // ref to track during click event whether the component was already focused when the click
  // started; this is used to avoid having the focus and click events both trying to change the
  // calendar open state and therefore causing it to close
  const alreadyFocused = useRef();

  /**
   * Handler for when mouse button is depressed on an element in the text field container. The
   * purpose of this callback is to check whether the component is already-focused, and only if it
   * is (and therefore the onFocus handler isn't going to activate) do we handle the click event.
   *
   * @type {function(): void}
   */
  const handleMouseDown = useCallback(() => {
    alreadyFocused.current = document.activeElement === inputRef.current;
  }, []);

  /**
   * Handler for when mouse button is released on an element in the text field container. If the
   * input field was already focused, this toggles the calendar open or closed. If the input wasn't
   * already focused, clicking focuses it (because this handler also captures click events for the
   * icons, which would not naturally cause the input to focus).
   *
   * @type {function:():void}
   */
  const handleMouseUp = useCallback(() => {
    if (alreadyFocused.current) {
      setCalendarOpen(!calendarOpen);
    } else {
      inputRef.current.focus();
    }
  }, [calendarOpen]);

  // reference to the root element of the calendar component, used to find the height of the
  // calendar in order to determine the appropriate orientation of it relative to the input
  const calendarRef = useRef();

  // tracks the orientation of the calendar element, either below (bottom) or above (top) the input
  // element
  const [calendarPosition, setCalendarPosition] = useState(props.fixedPosition || 'bottom');

  // when the calendar opens, check whether there's enough room below the input element to display
  // the full calendar there; if so, set (or leave) the orientation as "bottom", but if not, set
  // (or leave) the orientation as "top". This does mean that it is up to the consumer of this
  // component to ensure that there will be enough space either above or below the input for the
  // calendar - it won't open to the side in very short containers, for example.
  useLayoutEffect(() => {
    if (calendarOpen && !props.fixedPosition) {
      const calendarHeight = calendarRef.current.offsetHeight;
      const inputBottom =
        elementUtils.getPosition(inputRef.current).y + inputRef.current.offsetHeight;
      const parent = elementUtils.closestParentWithHiddenYOverflow(calendarRef.current);
      const parentHeight = parent === window ? parent.innerHeight : parent.clientHeight;

      if (calendarHeight + inputBottom > parentHeight) {
        if (calendarPosition !== 'top') {
          setCalendarPosition('top');
        }
      } else if (calendarPosition !== 'bottom') {
        setCalendarPosition('bottom');
      }
    }
  }, [calendarOpen, calendarPosition, props.fixedPosition]);

  /**
   * Handler for when the user presses Enter in the input field, indicating they want to select the
   * date they've entered.
   *
   * @type {function(*)}
   */
  const handleKeyPress = useCallback((evt) => {
    if (evt.key === 'Enter') {
      evt.stopPropagation();
      evt.preventDefault();
      commitInputDate();
    }
  }, [commitInputDate]);

  /**
   * Handler for when Escape is pressed in the input group: causes the calendar view to close, if it
   * is open.
   *
   * @type {function(*): void}
   */
  const handleKeyDown = useCallback((evt) => {
    if (evt.key === 'Escape') {
      setCalendarOpen(false);
    }
  }, []);

  /**
   * Handles validation of the input by forwarding validate prop to FieldGroup.
   *
   * @type {function(string, string): string[]}
   */
  const handleValidate = useCallback((name, val) => {
    return props.validate ? props.validate(name, val) : [];
  }, [props.validate]);

  return (
    <FieldGroup
      {...props}
      className={makeClassName(props.className, 'c-date-field__field-group',
        props.appearance && 'c-date-field__field-group--' + props.appearance)}
      autoComplete="off"
      handleChange={noOpHandleChange}
      handleFocus={handleFocus}
      onKeyDown={handleKeyDown}
      validate={handleValidate}
      render={({ required, ...otherInputProps }) => (
        <div
          className="c-date-field__input-container"
          ref={container}
          onKeyDown={handleKeyDown}
        >
          <div
            className="c-date-field__input-wrapper"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="c-date-field__input-calendar-icon"
            />
            <input
              {...otherInputProps}
              onChange={handleInputChange}
              value={inputDate}
              aria-required={required}
              className="c-date-field__input"
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              ref={inputRef}
            />
            <FontAwesomeIcon
              icon={faChevronDown}
              className={makeClassName(
                'c-date-field__input-arrow-icon',
                calendarOpen && 'c-date-field__input-arrow-icon--open'
              )}
            />
          </div>
          {
            calendarOpen && (
              <ReactCalendar
                calendarType={props.iso8601 ? 'ISO 8601' : "US"}
                className={makeClassName(
                  'c-date-field__calendar',
                  calendarPosition === 'bottom' && 'c-date-field__calendar--bottom',
                  calendarPosition === 'top' && 'c-date-field__calendar--top'
                )}
                inputRef={calendarRef}
                activeStartDate={calendarActiveStartDate}
                onActiveStartDateChange={handleActiveCalendarStartDateChange}
                onChange={handleChange}
                minDate={minDate.current}
                maxDate={maxDate.current}
                minDetail="decade"
                value={valueAsDate}
                showFixedNumberOfWeeks
                nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                next2Label={<FontAwesomeIcon icon={faChevronDoubleRight}/>}
                prevLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                prev2Label={<FontAwesomeIcon icon={faChevronDoubleLeft}/>}
                tileClassName={props.tileClassName}
              />
            )
          }
          <HandleBlurFromExternalEvent container={container} onBlur={handleBlur}/>
        </div>
      )}
    />
  );
};

DateField.propTypes = {
  ...FieldGroup.propTypes,
  appearance: PropTypes.oneOf(['updated-ui']),
  iso8601: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  fixedPosition: PropTypes.string
};

/**
 * Shim implementation to pass as FieldGroup's handleChange prop. FieldGroup itself doesn't actually
 * use that prop, and we don't use it from the props it passes down to its render prop.
 *
 * @param {*} _name - field name
 * @param {*} _value - field value
 */
const noOpHandleChange = (_name, _value) => {};

/**
 * Unambiguous date formats that we will accept regardless of user's locale.
 *
 * @type {string[]}
 */
const INPUT_DATE_FORMATS = [
  'yyyy-M-d',
  'd MMM yyyy',
  'd MMM. yyyy',
  'd MMMM yyyy',
  'MMM d, yyyy',
  'MMM. d, yyyy',
  'MMMM d, yyyy'
];

/**
 * Attempts to parse the given string as a Date.  Accepts all formats defined in INPUT_DATE_FORMATS,
 * as well any locale-specific date formats discovered by dateUtils.localDateFormats.  Returns null
 * if the string doesn't match any accepted format.
 *
 * @param {string} dateStr - the string to try to parse as date
 * @returns {Date}
 */
const parseInputDate = (dateStr) => {
  const result = dateUtils.dateFromString(
    dateStr,
    { formats: [...INPUT_DATE_FORMATS, ...dateUtils.localDateFormats()], noDateFallback: true }
  );
  if (isNaN(result.valueOf())) {
    return null;
  } else {
    return result;
  }
};

/**
 * Returns true if the provided dates are different, including the case where one is null and the
 * other is non-null.
 *
 * @param {Date} left - one of the two dates to compare
 * @param {Date} right - the date to compare left with
 * @returns {boolean}
 */
const datesDiffer = (left, right) => (
  !left !== !right || (!!left && left.getTime() !== right.getTime())
);

/**
 * Given a string or Date representation of a date, converts it into a displayable string format
 * for the UI.
 *
 * @param {string|Date} value
 * @returns {string}
 */
const displayDateFromValue = (value) => {
  if (value) {
    const parsed = dateUtils.dateFromString(value);
    if (parsed) {
      return dateUtils.formatDateMonthShortYear(parsed);
    } else {
      return '';
    }
  } else {
    return '';
  }
};
