import React from 'react';
import PropTypes from 'prop-types';
import { RadioSet, Radio } from './radio_set';
import './radio_group.sass';
import { makeClassName } from 'utils';

/**
 * Radio group component. Uses a radio set to display a list of radio options.
 *
 * <Form.RadioGroup
 *   handleChange={(name, val) => this.setState({value: val})}
 *   name="myradio"
 *   label="My Radio"
 *   options={[
 *     {selectionValue: true, displayValue: 'Yes'},
 *     {selectionValue: false, displayValue: 'No', child: <div>Child</div>}
 *   ]}
 * >
 *
 * @property {function(string)} handleChange - Called when a radio option is selected.
 * @property {String} name - The shared input name of all radio buttons in the set.
 * @property {String} value - The currently selected value.
 * @property {String} className - Additional CSS class names.
 * @property {String} label - The label of the radio group.
 * @property {String} headLabel - The header label of the radio group.
 * @property {Boolean} required - Whether the input is required.
 * @property {Object} options - An array of radio options to display.
 * @property {String} options[].selectionValue - The value of the radio option.
 * @property {String} options[].displayValue - The display value of the radio option.
 * @property {React.element} options[].child - A child element to display when the radio option is selected.
 * @property {React.element} options[].hide - Used when you want to hide an option. This is used
 *  to simplify the logic when you have conditional options.
 */
export const RadioGroup = props => {
  const validate = (name, val) => {
    return props.required && (val === null || val === undefined) ? ['is required'] : [];
  };

  const className = makeClassName('c-radio-group__set',
    props.appearance && `c-radio-group__set--${props.appearance}`,
    props.className);

  return (
    <RadioSet
      {...props}
      noPadding
      validate={validate}
    >
      <div className={className}>
        {props.options.map((option, idx) => {
          if (option.hide) {
            return null;
          }
          let headLabelDisplayValue = option.selectionName;
          if (props.labelTagForOption === idx) {
            headLabelDisplayValue = <>{option.selectionName} {props.labelTag}</>;
          }
          return (
            <React.Fragment key={option.selectionValue}>
              <Radio
                appearance={props.appearance}
                headLabel={headLabelDisplayValue}
                value={option.selectionValue}
                label={option.displayValue}
                disabled={props.disableAll}
              />
              {(option.child && props.value === option.selectionValue) && option.child}
            </React.Fragment>
          );
        })}
      </div>
    </RadioSet>
  );
};

RadioGroup.propTypes = {
  appearance: PropTypes.oneOf([
    'long-button-style',
    'button-style',
    'radio-style'
  ]),
  className: PropTypes.string,
  /**
   * Sets each Radio as disabled and overrides option level disabling.
   */
  disableAll: PropTypes.bool,
  /**
   * When the value of the field is changed
   */
  handleChange: PropTypes.func.isRequired,
  /**
   * The text that is displayed over the label
   */
  headLabel: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  /**
   * The text that is displayed over the field
   */
  label: PropTypes.node,
  /**
   * The number of the radio button in the list to get tagged
   */
  labelTagForOption: PropTypes.number,
  /**
   * The tag to display on the labelTagForOption radio button
   */
  labelTag: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  /**
   * The name of the field
   */
  name: PropTypes.string,
  /**
   * The field must be selected to submit the form
   */
  required: PropTypes.bool,
  /**
   * The value of the field
   */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * The radio options for the field. An array of objects with the following shape:
   *  { selectionValue: required, displayValue: required }
   */
  options: PropTypes.array
};
