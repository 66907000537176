import PropTypes from 'prop-types';

/**
 * Wrapper to render form fields outside of a Form or LocalForm.  Primarily exists to avoid problems
 * that can be caused by form fields lazy loading.
 *
 * @param {object} props
 * @param {*} props.children - the content containing form fields to render
 * @returns {*}
 * @constructor
 */
export const FormFieldWrapper = (props) => props.children;

FormFieldWrapper.propTypes = {
  children: PropTypes.node
};
