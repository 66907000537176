import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as objUtils from 'utils/object';
import { Action } from 'utils/action';
import { Form } from 'form/form';
import Checkbox from 'form/checkbox';
import { useFormProps } from 'form/use_form_props';
import ActionIndicator from 'controls/action_indicator';

/**
 * A self-submitting checkbox. This class can be connected to redux like this:
 * <MiniformCheckbox action={someAction}/>
 *
 * or it can be left unconnected:
 * <MiniformCheckbox submit={onSubmit}/>
 *
 * @param {Object} props
 * @returns {React.element}
 * @constructor
 */
export const MiniformCheckbox = props => {
  const [formProps] = useFormProps(props.action, objUtils.except(props, Object.keys(localProps)));

  return (
    <Form
      {...formProps}
      className={props.className}
      submitOnChange
      resetOnInitialValuesChange
    >
      {form =>
        <Checkbox
          {...form.propsForFieldGroup(props.name)}
          label={<Fragment>
            {props.label}
            <ActionIndicator {...formProps.submitStatus}/>
          </Fragment>}
        />
      }
    </Form>
  );
};

const localProps = {
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  action: PropTypes.instanceOf(Action)
};

MiniformCheckbox.propTypes = {
  ...Form.propTypes,
  ...localProps,
  children: PropTypes.any
};
