import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Textarea } from "./textarea";
import { FormattedMessage } from "react-intl";

import './limit_textarea.sass';

export const LimitTextarea = (props) => {
  const [finalLength, setFinalLength] = useState(() => {
    if (!props.value) {
      return 0;
    }
    if (props.limitType === "words") {
      return props.value.trim().split(/\s+/).length;
    } else {
      return props.value.length;
    }
  });
  // This label supplement is the old version of the character count
  const composeLabelSupplement = () => {
    if (props.limitType === "words") {
      return (
        <FormattedMessage
          id="settings.profile.words_with_value"
          defaultMessage=" ({value} words)"
          values={{
            value: `${props.value ? finalLength : "0"} / ${props.limit}`
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="settings.profile.characters"
          defaultMessage=" ({value} characters)"
          values={{
            value: `${props.value ? finalLength : "0"} / ${props.limit}`
          }}
        />);
    }
  };

  const handleChange = useCallback((input) => {
    let length;
    let text = input;
    if (props.limitType === 'words') {
      const wordArray = text?.trim().split(/\s+/);
      length = input ? wordArray?.length : 0;
      length = length >= props.limit ? props.limit : length;
      if (wordArray.length > props.limit) {
        text = wordArray.slice(0, props.limit).join(' ');
      } else if (wordArray.length === props.limit) {
        text = text?.trimEnd();
      } else {
        text = text?.trimStart();
      }
    } else {
      length = input ? input.length : 0;
    }
    setFinalLength(length);
    props.handleChange(props.name, text);
  }, [props.handleChange]);

  return (
    <Textarea
      {...props}
      maxLength={props.limitType === 'words' ? null : props.limit}
      labelSupplement={composeLabelSupplement()}
      handleChange={(name, value) => handleChange(value)}
    />
  );
};

LimitTextarea.propTypes = {
  ...Textarea.propTypes,
  limit: PropTypes.number.isRequired,
  limitType: PropTypes.oneOf(['words', 'characters']),
  value: PropTypes.string
};

LimitTextarea.defaultProps = {
  limit: 500,
  limitType: "characters"
};
