import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeClassName, uniqueId } from 'utils';
import element from 'utils/element';
import { setStateIfChanged } from 'utils/react_utils';
import { HandleBlurFromExternalEvent } from 'controls/handle_blur_from_external_event';
import FieldGroup from 'form/field_group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';

import './select.sass';

/**
 * @typedef SelectOption
 * @property {string|number|boolean} selectionValue - The form value of this option.
 * @property {string|number|node} displayValue - The displayed text of this option.
 */
let _DummySelectOption; // Needed for JSDoc.


/**
 * Simulate a select box.
 *
 * There's one important way this is different from <select>:
 * In order to accommodate screen readers, the "value" property of the embedded <input> is the
 * user-friendly text. The data submitted to <Form> is still the value that you'd expect.
 */
export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIndex: this.findIndex(props.value),
      highlightedIndex: -1
    };

    this.m_ref = React.createRef();
    this.m_inputRef = React.createRef();
    this.m_id = uniqueId();
  }

  /**
   * The component just updated. Adjust selectedIndex if needed.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options || prevProps.value !== this.props.value) {
      setStateIfChanged(this, { selectedIndex: this.findIndex(this.props.value) });
    }
  }

  /**
   * An item has been clicked.
   *
   * @param {Object} item
   * @param {int} index
   */
  onSelect = (item, index) => {
    index = this.displayIdxToOptionIdx(index);
    this.setState({
      selectedIndex: this.props.skipSelectionDisplay ? null : index,
      isOpen: false,
      highlightedIndex: -1
    }, () => {
      this.props.handleChange(this.props.name, item.selectionValue);

      // The element blurs when the menu is clicked. Re-focus it.
      this.m_inputRef.current && this.m_inputRef.current.focus();
    });
  };

  /**
   * An item has been highlighted using the mouse.
   *
   * @param {Object} item
   * @param {int} index
   */
  onHighlight = (item, index) => {
    this.setState({ highlightedIndex: this.displayIdxToOptionIdx(index) });
  };

  /**
   * Convert an index from the visual options list to its corresponding index in the options array.
   *
   * @param {number} index
   * @returns {number}
   */
  displayIdxToOptionIdx(index) {
    return this.hasResetOption() ? index - 1 : index;
  }

  /**
   * Convert an index from the visual options list to its corresponding index in the options array.
   *
   * @param {number} index
   * @returns {number}
   */
  optionIdxToDisplayIdx(index) {
    return this.hasResetOption() ? index + 1 : index;
  }

  /**
   * Get the options to be displayed in the menu. If props.resetOption is set, this will include
   * one extra item.
   */
  displayOptions() {
    if (this.hasResetOption()) {
      return [{ displayValue: this.props.resetOption }, ...this.props.options];
    } else {
      return this.props.options;
    }
  }

  /**
   * Is there a reset option? A reset option is an option which nulls out the field.
   *
   * @returns {Boolean}
   */
  hasResetOption() {
    return this.props.resetOption !== undefined;
  }

  /**
   * Find the index of a given selectionValue, or 0 if the value isn't in the list.
   *
   * @param {String|Number|Boolean} value
   * @returns {int}
   */
  findIndex(value) {
    const idx = this.props.options.findIndex(o => o.selectionValue === value);
    if (idx < 0 && this.props.selectByDefault !== undefined) {
      return this.props.options.findIndex(o => o.selectionValue === this.props.selectByDefault);
    }
    return idx;
  }

  /**
   * @returns {SelectOption} the currently selected item.
   */
  getSelectedItem() {
    return this.props.options[this.state.selectedIndex];
  }

  /**
   * @returns {SelectOption} the currently highlighted item (used during keyboard navigation).
   */
  getHighlightedItem() {
    return this.props.options[this.state.highlightedIndex];
  }

  /**
   * Open or close the menu.
   */
  toggleMenu = () => {
    this.setState(state =>
      ({ isOpen: !state.isOpen, highlightedIndex: state.selectedIndex }), () => {
      this.scrollToItem(this.state.highlightedIndex);
    });
  };

  /**
   * There was a click outside the element.
   */
  onExternalBlur = () => {
    setStateIfChanged(this, { isOpen: false, highlightedIndex: -1 });
  };

  validate = (name, val) => {
    if (!val) {
      const type = this.props.type;
      // These validators are not exactly the same as the ones we use in Ruby, but that's okay.
      // It just means that if these fail, we'll fall through to server-side validation.
      if (type === 'variant') {
        return ["can't be blank. Please make a selection in the"];
      }
    }

    // Check other validators
    return this.props.validate ? this.props.validate(name, val) : [];
  };

  /**
   * Keyboard control of the select box.
   *
   * @param {SyntheticEvent} evt
   */
  onKeyDown = evt => {
    if (evt.metaKey || evt.ctrlKey) {
      return;
    }

    const options = this.props.options;
    const curIdx = this.state.isOpen ? this.state.highlightedIndex : this.state.selectedIndex;
    const lastIdx = options.length - 1;
    let allowPropagation = false;
    const newState = {};
    let newSelectedIndex;

    switch (evt.key) {
      case 'Tab':
        // Close the menu and move on to the next item in the tab order.
        newState.isOpen = false;
        allowPropagation = true;
        break;
      case ' ':
        newState.isOpen = true;
        break;
      case 'Escape':
        newState.isOpen = false;
        if (!this.state.isOpen) {
          allowPropagation = true;
        }
        break;
      case 'ArrowDown':
        if (evt.altKey) {
          // Compatibility with NVDA commands
          newState.isOpen = true;
        } else {
          // Move down the list, then loop to the top.
          newState.highlightedIndex = curIdx >= lastIdx ? 0 : curIdx + 1;
        }
        break;
      case 'ArrowUp':
        // Move up the list, then loop to the bottom.
        newState.highlightedIndex = curIdx <= 0 ? lastIdx : curIdx - 1;
        break;
      case 'PageDown':
        // Move down a page, stopping at the bottom.
        newState.highlightedIndex = Math.min(curIdx < 0 ? 5 : curIdx + 5, lastIdx);
        break;
      case 'PageUp':
        // Move up a page, stopping at the top.
        newState.highlightedIndex = Math.max(0, curIdx < 0 ? lastIdx - 5 : curIdx - 5);
        break;
      case 'Home':
        newState.highlightedIndex = 0;
        break;
      case 'End':
        newState.highlightedIndex = lastIdx;
        break;
      case 'Enter':
        newState.isOpen = false;
        if (curIdx >= 0 && this.state.isOpen) {
          newSelectedIndex = curIdx;
        }

        // If it's closed, allow enter to submit the form. Both this and the onKeyPress handler are
        // necessary.
        if (!this.state.isOpen) {
          allowPropagation = true;
        }
        break;
      default:
        if (evt.altKey) {
          return;
        }
        // Find an option starting with the typed character.
        const key = evt.key.toString().toLowerCase();

        const firstLetter = s => {
          s = s.toString();
          return s && s[0].toLowerCase();
        };

        // If we're already on an item starting with the character, find the next instance.
        const curHighlight = this.getHighlightedItem();
        if (curHighlight && firstLetter(curHighlight.displayValue) === key) {
          const next = options[curIdx + 1];
          if (next && firstLetter(next.displayValue) === key) {
            newState.highlightedIndex = curIdx + 1;
          }
        }

        // Find the first instance.
        if (!newState.highlightedIndex) {
          const idx = options.findIndex(option => firstLetter(option.displayValue) === key);
          if (idx > -1) {
            newState.highlightedIndex = idx;
          }
        }
    }

    if (!allowPropagation) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if ('highlightedIndex' in newState) {
      if (!this.state.isOpen) {
        // Select the item if the menu is closed.
        newSelectedIndex = newState.highlightedIndex;
        delete newState.highlightedIndex;
      } else {
        this.scrollToItem(newState.highlightedIndex);
      }
    }

    let stateCallback = null;
    // Handle selectedIndex separately from the setState below, so componentDidUpdate can get it
    // from the form value. That way, the form and this component will always be in sync.
    if (newSelectedIndex !== undefined) {
      stateCallback =
        () => this.props.handleChange(this.props.name, options[newSelectedIndex].selectionValue);
    }

    setStateIfChanged(this, newState, stateCallback);
  };

  /**
   * Scroll to a specific option.
   *
   * @param {number} idx - The index of the itme to scroll to
   */
  scrollToItem(idx) {
    if (idx >= 0 && this.state.isOpen) {
      const menu = this.m_ref.current.querySelector('ul');
      const highlightedElt = menu.querySelector(`li:nth-child(${idx + 1})`);
      if (highlightedElt) {
        menu.scrollTop = highlightedElt.offsetTop;
      } else {
        console.error('Select: failed to scroll to option.');
      }
    }
  };

  /**
   * In order to prevent the form from submitting on enter when the select box is open, onKeyPress
   * must be handled.
   *
   * @param {SyntheticEvent} evt
   */
  onKeyPress = evt => {
    if (evt.key === 'Enter' && this.state.isOpen) {
      evt.preventDefault();
    }
  };

  /**
   * Render the component field.
   *
   * @param {Object} props - Defined by FieldGroup.
   *  @param {String} props.id
   *  @param {String} props.placeholder
   *  @param {Boolean} props.required
   *  @param {Object} props.fieldProps Other props defined in FieldGroup
   * @returns {*}
   */
  renderField = ({ id, placeholder, required, ...fieldProps }) => {
    const selectedItem = this.getSelectedItem();
    const highlightedItem = this.getHighlightedItem();
    const menuId = `select_menu_${this.m_id}`;

    const inputAria = {
      'aria-haspopup': 'listbox',
      'aria-labelledby': `${fieldProps['aria-labelledby']} ${id}`,
      'aria-expanded': this.state.open,
      'aria-owns': menuId
    };

    let displayValue;
    if (this.props.skipSelectionDisplay) {
      displayValue = '';
    } else {
      displayValue = selectedItem ? selectedItem.displayValue : placeholder;
    }

    // This is a work-around for how Google Translate breaks the page.
    // Google Translate replaces the text node with a <font>, and react doesn't like that, so it
    // throws an error upon re-rendering. Creating this functional component is necessary
    // because react will blanket replace this node rather than trying to remove and replace the
    // children.
    const DisplayValue = ({ value }) => <span>{value}</span>;

    const selectIconClassName = makeClassName(
      this.props.rotateIcon && "c-select__icon",
      !this.props.rotateIcon && "c-select__icon-no-rotate"
    );

    const getIcon = () => {
      if (this.props.disabled) {
        return faLock;
      }
      return this.props.icon || faChevronDown;
    };

    return (
      <div
        ref={this.m_ref}
        className={makeClassName(
          'c-select__combobox',
          this.state.isOpen && 'c-select__combobox-open',
          !this.state.isOpen && 'c-select__combobox-closed',
          this.props.disabled && 'c-select__combobox-disabled'
        )}
      >
        <button
          id={id}
          ref={this.m_inputRef}
          type="button"
          // inputAria needs to be spread after fieldProps to override aria-labelledBy
          {...fieldProps}
          {...inputAria}
          // <Form> holds the real value. Here, we display the user-frieldly text.
          className={makeClassName([
            this.props.inputClassName,
            'c-select__input',
            !this.props.label && 'c-select__input-no-label'
          ])}
          onClick={this.toggleMenu}
          onKeyDown={this.onKeyDown}
          onKeyPress={this.onKeyPress}
        >
          {this.props.leftIcon &&
            <FontAwesomeIcon icon={this.props.leftIcon} className="c-select__left-icon" />
          }
          <div
            className={makeClassName([
              'c-select__input-text',
              !selectedItem && 'c-select__input-font-color-no-selection'
            ])}
          >
            <DisplayValue value={displayValue} />
          </div>
          <FontAwesomeIcon icon={getIcon()} className={selectIconClassName} />
        </button>
        <SelectMenu
          onSelect={this.onSelect}
          onKeyDown={this.onKeyDown}
          onKeyPress={this.onKeyPress}
          onHighlight={this.onHighlight}
          className={this.props.optionsClassName}
          options={this.displayOptions()}
          selectedItem={selectedItem}
          highlightedItem={highlightedItem}
          isOpen={this.state.isOpen}
          menuId={menuId}
          label={placeholder}
          required={required}
        />
      </div>
    );
  };

  /**
   * Render the component.
   *
   * @returns {*}
   */
  render() {
    // Simplify the UI if it's readOnly.
    if (this.props.readOnly) {
      const selectedItem = this.getSelectedItem();
      return (
        <FieldGroup
          {...this.props}
          render={({ required: _required, id, labelledBy, ...otherProps }) => (
            <div
              id={id}
              className="c-select__input"
              role="textbox"
              aria-labelledby={`${labelledBy}`}
              aria-readonly
              tabIndex={0}
            >
              <input type="hidden" readOnly {...otherProps} />
              {selectedItem ? selectedItem.displayValue : ''}
            </div>
          )}
        />
      );
    } else {
      return (
        <React.Fragment>
          <FieldGroup
            {...this.props}
            // The first thing that happens when you select an item, is that the input loses focus.
            // This triggers a {focused: false} state change in FieldGroup. FieldGroup re-renders,
            // calling renderField(), which causes screen readers to read the current, now stale,
            // select value. When SelectMenu.onClick is finally called and the value is changed,
            // screen readers will continue to read the old version, and ignore the change.
            // noFocusStyle prevents that transition. This could also have been fixed with
            // aria-live=assertive, but then the value would be read when the page loads, and at
            // other inappropriate times.
            //
            // Note that, even with this change (or aria-live), the screen reader may still stutter,
            // as it starts reading the previous value, but then reads the new one.
            noFocusStyle
            validate={this.validate}
            link={this.props.link}
            className={makeClassName(
              'c-select__field-group',
              this.props.className,
              this.props.appearance && 'c-select__field-group--' + this.props.appearance,
              this.props.inverse && 'c-select--inverse'
            )}
            // Don't optimize to render={this.renderField}. This must be new function (as opposed to a
            // function pointer), to force React to re-render FieldGroup when state changes.
            render={fieldProps => this.renderField(fieldProps)}
          />

          <HandleBlurFromExternalEvent container={this.m_ref} onBlur={this.onExternalBlur} />
        </React.Fragment>
      );
    }
  }
}

Select.propTypes = {
  appearance: PropTypes.oneOf([
    'horizontal-label'
  ]),
  /**
   * The inherited FieldGroup props
   */
  ...FieldGroup.propTypes,
  /**
   * The reset option
   */
  resetOption: PropTypes.string,
  /**
   * If nothing is selected, select this value. Be careful when using this property, since the
   * selected value may not match the value in <Form>'s state. Only use this for defaults.
   */
  selectByDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  /**
   * The text that represents the placholder value
   */
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  /**
   * The options will be displayed in the dropdown box
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      selectionValue:
        PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      displayValue:
        PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired
    })
  ).isRequired,
  /**
   * Invert the color, making the button white on blue.
   */
  inverse: PropTypes.bool,
  /**
   * Specific styles for the input control.
   */
  inputClassName: PropTypes.string,
  /**
   * Styles for the options drop-down.
   */
  optionsClassName: PropTypes.string,
  /**
   * If true, does not update the input with the selected value
   */
  skipSelectionDisplay: PropTypes.bool,
  rotateIcon: PropTypes.bool
};

Select.defaultProps = {
  options: [],
  placeholder: 'Select One',
  skipSelectionDisplay: false,
  rotateIcon: true
};

/**
 * Render the options. This component is memoized for performance, meaning that it's only
 * re-rendered when props change. This is okay despite the use of the "style" state, because the
 * state is directly derived from props.
 *
 * @param {Object} props
 *   @param {function} props.getMenuProps - Function to get menu properties
 *   @param {function} props.getItemProps - Function to get item properties
 *   @param {Boolean} props.isOpen - Is the menu currently open?
 *   @param {SelectOption} props.selectedItem - The currently selected item
 *   @param {int} props.highlightedIndex - The currently highlighted item
 *   @param {array<object>} props.options -- Menu options
 * @returns {*}
 * @constructor
 */
const SelectMenu = React.memo(props => {
  const ref = React.useRef();
  const [style, setStyle] = React.useState({});
  const selectedItem = props.selectedItem;
  const highlightedItem = props.highlightedItem;

  // If the menu falls off the screen when opened, pull it back up.
  React.useLayoutEffect(() => {
    if (props.isOpen) {
      setStyle(element.moveWithin(ref.current, element.closestScrollingParent(ref.current)));
      ref.current.focus();
    } else {
      setStyle({ marginLeft: '', marginTop: '' });
    }
  }, [props.isOpen, props.options.length, props.className]);

  const className = makeClassName([
    'c-select__options',
    props.isOpen && 'c-select__options--open',
    props.className
  ]);

  const makeItemClassName = item => makeClassName([
    'c-select__option',
    highlightedItem === item && 'c-select__option--highlighted',
    selectedItem === item && 'c-select__option--selected'
  ]);

  const highlightedItemId = highlightedItem &&
    props.options.findIndex((item) => item.displayValue === highlightedItem.displayValue);

  return (
    <ul
      id={props.menuId}
      tabIndex={-1}
      ref={ref}
      className={className}
      style={style}
      role="listbox"
      aria-label={props.label}
      onKeyPress={props.onKeyPress}
      onKeyDown={props.onKeyDown}
      aria-activedescendant={`${props.menuId}-${highlightedItemId || 0}`}
    >
      {props.options.map((item, index) => {
        const ariaOpts = { role: 'option', 'aria-selected': false, 'aria-label': item.displayValue, 'aria-required': props.required };
        if (props.isOpen) {
          ariaOpts.id = `${props.menuId}-${index}`;
        }

        if ((selectedItem && selectedItem.displayValue === item.displayValue)) {
          ariaOpts['aria-selected'] = true;
        }

        return (
          <li
            {...ariaOpts}
            className={makeItemClassName(item)}
            key={item.displayValue}
            onClick={() => props.onSelect(item, index)}
            onMouseEnter={() => props.onHighlight(item, index)}
          >
            {item.displayValue}
          </li>
        );
      })}
    </ul>
  );
});
SelectMenu.displayName = 'SelectMenu';


SelectMenu.propTypes = {
  menuId: PropTypes.string,
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onHighlight: PropTypes.func.isRequired,
  highlightedItem: PropTypes.object,
  selectedItem: PropTypes.object,
  options: Select.propTypes.options,
  className: PropTypes.string,
  label: PropTypes.string,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  required: PropTypes.bool
};

export const testing = { SelectMenu };
