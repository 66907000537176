import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import FieldGroup from "./field_group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { makeClassName } from 'utils';

import "./textarea.sass";

export const Textarea = (props) => {
  /**
   * Handles update to the value of the textarea field by notifying the parent component of the new
   * value (if indeed there has been a change) and if allowLineBreaks is FALSE not allow line breaks.
   *
   * @type {function(event):void}
   */

  const handleChange = useCallback((event) => {
    const value = event.target.value;
    if (!props.allowLineBreaks) {
      const newValue = value.replace(/\n/g, "");
      props.handleChange(props.name, newValue);
    } else {
      props.handleChange(props.name, value);
    }
  }, [props.handleChange]);

  const getIcon = () => {
    if (props.disabled) {
      return faLock;
    }
    return props.icon || null;
  };

  return (
    <FieldGroup
      {...props}
      tall
      render={({ required, ...otherProps }) => (
        <>
          <textarea
            {...otherProps}
            aria-required={required}
            aria-disabled={props.disabled}
            className={makeClassName(
              'c-textarea',
              props.disabled && 'c-textarea__disabled'
            )}
            onChange={handleChange}
          />
          {props.disabled &&
            <FontAwesomeIcon icon={getIcon()} className="c-textarea__disabled-icon" />
          }
        </>
      )
      }
    />
  );
};

Textarea.propTypes = {
  ...FieldGroup.propTypes,
  allowLineBreaks: PropTypes.bool,
  handleChange: PropTypes.func,
  limitType: PropTypes.oneOf(['words', 'characters'])
};

