import React from 'react';
import PropTypes from 'prop-types';
import TextField from './text_field';

import './range_group.sass';

/**
 * Creates a range component consisting of two text fields with 'number' type.
 *
 * USAGE:
 * <Form.RangeGroup
 *   minimumField={propsForFieldGroup('[minimum_field_name_goes_here]')}
 *   maximumField={propsForFieldGroup('[maximum_field_name_goes_here]')}
 *   required
 *   enclosed
 *   minimumLabel="Lowest"
 *   maximumLabel="Highest"
 * />
 *
 * @param {Object} props
 *  @param {Integer} props.inputMax - indicates the maximum allowed input for this group.
 *  @param {Boolean} props.isCurrency - indicates whether the input is currency or not.
 *  @param {Object} props.minimumField - propsForFieldGroup of the minimum field.
 *  @param {Object} props.maximumField - propsForFieldGroup of the maximum field.
 *  @param {Function(string, string)} props.onHandleChange - called to update the value of the parent
 *    object with the values in the minimum and maximum fields.
 *  @param {Boolean} props.required - indicates whether both fields are requried.
 *  @param {Boolean} props.enclosed - indicates whether both fields should be enclosed.
 *  @param {Object} props.minimumLabel - Label for the minimum textfield, overrides the name from minimumField.
 *  @param {Object} props.maximumLabel - Label for the maximum textfield, overrides the name from maximumField.
 * @returns {JSX.Element}
 */
export const RangeGroup = (props) => {
  return (
    <div className="range-group" >
      <div className="range-group__input">
        <TextField
          {...props.minimumField}
          label={props.minimumLabel ? props.minimumLabel : 'Minimum'}
          className="range-minimum"
          type="number"
          inputMax={props.inputMax}
          isCurrency={props.isCurrency}
          required={props.required}
          enclosed={props.enclosed}
        />
        <div className="range-group__divider"> - </div>
        <TextField
          {...props.maximumField}
          label={props.maximumLabel ? props.maximumLabel : 'Maximum'}
          className="range-maximum"
          type="number"
          inputMax={props.inputMax}
          isCurrency={props.isCurrency}
          required={props.required}
          enclosed={props.enclosed}
        />
      </div>
    </div>
  );
};

RangeGroup.propTypes = {
  minimumField: PropTypes.object.isRequired,
  maximumField: PropTypes.object.isRequired,
  inputMax: PropTypes.number,
  isCurrency: PropTypes.bool,
  required: PropTypes.bool,
  enclosed: PropTypes.bool,
  minimumLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  maximumLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};
