import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './invisible_input.sass';

/**
 * A "hidden" input with opacity set near to zero. Used with components such as Radio or Checkbox
 * so an input exists with a completely separate UI component
 */
export const InvisibleInput = (props) => (
  <input
    {...props}
    className={
      makeClassName(
        'cr-invisible-input',
        props.className,
        props.disabled && 'cr-invisible-input--disabled')
    }
  />
);

InvisibleInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool
};
