import React, { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';
import { processedLocationData } from 'app_utils/location_data';
import { useStandardLocalMatches } from 'controls/search/search_hooks';
import { TypeaheadSelect } from 'form/typeahead_select.jsx';
import { Select } from "./select";
import { uniqueId } from 'utils';


export const CountryTypeaheadSelectOrDropdown = (props) => {
  const intl = useIntl();
  const localizedCountries = useMemo(() => {
    const allowedCountries = processedLocationData.getAllowedCountries(props.useId);
    const countries = allowedCountries.map((country) => ({
      ...country,
      displayValue: intl.formatMessage({
        id: intlKeyFromValue(country.displayValue, 'country'),
        defaultMessage: country.displayValue
      })
    }));
    if (intl.locale.startsWith('en')) {
      // US and Canada are promoted to the top of the list by the backend, leave it like that when
      // the locale is English and users are likely to be from one of those two countries
      return countries;
    } else {
      const collator = new Intl.Collator(intl.locale);
      return countries.sort((a, b) => collator.compare(a.displayValue, b.displayValue));
    }
  }, [props.useId, intl.locale]);

  const localMatches = useStandardLocalMatches(localizedCountries, 'displayValue', true);

  const handleChange = useCallback((name, value) => {
    let sanitizedValue;

    if (props.useId) {
      sanitizedValue = value?.selectionValue ?? "";
    } else {
      sanitizedValue = value?.displayValue ?? "";
    }

    props.handleChange(name, sanitizedValue);
  }, [props.handleChange, props.useId]);

  const idPrefix = (i) => `country_typeahead_resource_${i}`;
  const [id] = React.useState(
    idPrefix(uniqueId())
  );

  if (props.typeaheadSelect) {
    // Return typeahead if prop is given
    return (
      <TypeaheadSelect
        {...props}
        key={id}
        handleChange={handleChange}
        localMatches={localMatches}
        displayValueAttribute="displayValue"
      />
    );
  } else {
    return (
      <Select
        {...props}
        options={localizedCountries}
        onChange={props.onChange}
      />
    );
  }
};

CountryTypeaheadSelectOrDropdown.propTypes = {
  ...TypeaheadSelect.propTypes
};

CountryTypeaheadSelectOrDropdown.defaultProps = {
  ...TypeaheadSelect.defaultProps,
  label: 'Country/Territory'
};
