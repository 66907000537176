import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounced, useImmediateEffect } from 'utils/react_utils';
import * as arrayUtils from 'utils/array';
import * as stringUtils from 'utils/string';

const EMPTY_ARRAY = [];
const DEFAULT_DELAY = 300;

/**
 * @typedef {Object} useSearchOnTypeResponse
 * @property {boolean} searching - True if we are currently searching.
 * @property {array} results - The results of the search. If searching is true, this will be the local matches.
 */

/**
 * A hook that will trigger a search when the query changes. This hook is designed to be used with
 *   ActionManager but options.reactQuery can be set to true to use with ReactQuery.
 * @param {object} searchState - An object representing the state of the search. This object
 *   is either from ActionManager or ReactQuery
 * @param performSearch - The function to call to perform the search. This will get called
 *   with the normalized query string.
 * @param {string} rawQuery - The raw query string  (e.g. "Badge Template 143"). This string will
 *   be trimmed and set to lowercase before sending to performSearch.
 * @param {object} options - An object with the following optional properties:
 * @param {integer} options.delay - The delay in milliseconds before calling performSearch. Default is 300.
 * @param {boolean} options.searchOnEmpty - If true, performSearch will be called with an empty string.
 * @param {function} options.localMatches - The response of a useStandardLocalMatches hook. A function that will be
 *   called with the normalized query string and should return an array of local matches.
 * @param {boolean} options.active - If false, performSearch will not be called.
 * @param {boolean} options.reactQuery - If false (default), the searchState is inferred to be from an Action hook
 *  If true, the searchState is inferred to be from a RQ useQuery hook.
 *  See https://tanstack.com/query/v4/docs/framework/react/reference/useQuery for more info
 *
 * @returns {useSearchOnTypeResponse} An object containing `searching` and `results`.
 */

export const useSearchOnType = (searchState, performSearch, rawQuery, options = {}) => {
  if (!options.delay) {
    options.delay = DEFAULT_DELAY;
  }

  const [requestedQuery, setRequestedQuery] = useState(null);
  const normalizedQuery = stringUtils.normalizeCaseInsensitive(rawQuery);

  let searchResults;
  if (options.reactQuery) {
    searchResults = searchState?.data?.data || [];
  } else {
    searchResults = searchState?.resources || [];
  }

  const debouncedPerformSearch = useDebounced(() => {
    if (normalizedQuery === requestedQuery) {
      return;
    }
    setRequestedQuery(normalizedQuery);
    if (performSearch) {
      return performSearch(normalizedQuery);
    }
  }, options.delay);

  const debouncedPerformSearchRef = useRef();
  debouncedPerformSearchRef.current = debouncedPerformSearch;

  useEffect(() => {
    if ((normalizedQuery || options.searchOnEmpty) && options.active) {
      return debouncedPerformSearchRef.current();
    }
  }, [normalizedQuery, options.active]);

  let localMatches = EMPTY_ARRAY;
  if (options.localMatches) {
    localMatches = options.localMatches(normalizedQuery);
  }

  let searching = false;
  if (!!normalizedQuery) {
    // 2 cases will return searching = true
    // Case 1. When normalizedQuery !== requestedQuery (aka there is a new query we haven't searched on yet)
    // Case 2. When the searchState is pending or idle (we are in the act of searching)
    // Both of these cases depend on the presence of normalizedQuery

    // Case 1.
    if (normalizedQuery !== requestedQuery) {
      searching = true;
    }

    // Case 2.
    if (options.reactQuery) {
      // Check if the ReactQuery hook is loading
      if (searchState?.isFetching) {
        searching = true;
      }
    } else {
      // Check if the Action hook is pending
      if (searchState?.status?.pending || searchState?.status?.idle) {
        searching = true;
      }
    }
  }

  const mergeMatches = useMergeMatches();

  return {
    searching,
    results: searching ? localMatches : mergeMatches(localMatches, searchResults)
  };
};

export const useStandardLocalMatches = (searchData, matchAttribute = 'name', searchOnEmpty = false) => {
  const lastMatchedQuery = useRef();
  const matches = useRef();
  const lastMatchedSearchData = useRef();
  useImmediateEffect(() => {
    lastMatchedSearchData.current = null;
  }, [searchData, matchAttribute]);

  return useCallback((query) => {
    if (query === '') {
      if (searchOnEmpty) {
        return searchData;
      } else {
        return EMPTY_ARRAY;
      }
    }

    if (lastMatchedSearchData.current !== searchData || lastMatchedQuery.current !== query) {
      lastMatchedSearchData.current = searchData;
      lastMatchedQuery.current = query;

      const matching = searchData.filter((item) => {
        return stringUtils.normalizeCaseInsensitive(item[matchAttribute])
          .includes(stringUtils.normalizeCaseInsensitive(query));
      });
      matches.current = matching.sort((left, right) => {
        const leftNormalized = stringUtils.normalizeCaseInsensitive(left[matchAttribute]);
        const rightNormalized = stringUtils.normalizeCaseInsensitive(right[matchAttribute]);
        const leftPosition = leftNormalized.indexOf(query);
        const rightPosition = rightNormalized.indexOf(query);
        if (leftPosition < 0) {
          if (rightPosition >= 0) {
            return 1;
          }
          return 0;
        } else if (rightPosition < 0) {
          return -1;
        } else {
          const positionCompare = leftPosition - rightPosition;
          return (
            positionCompare === 0 ? leftNormalized.localeCompare(rightNormalized) : positionCompare
          );
        }
      });
    }
    return matches.current;
  }, [searchData, matchAttribute]);
};

const useMergeMatches = (identity = 'id') => {
  const merged = useRef();
  const mergedIds = useRef();
  const lastLocal = useRef();
  const lastRemote = useRef();
  useImmediateEffect(() => {
    merged.current = null;
    mergedIds.current = null;
    lastLocal.current = null;
    lastRemote.current = null;
  }, [identity]);

  return useCallback((local, remote) => {
    if (lastLocal.current === local && lastRemote.current === remote) {
      return merged.current;
    }
    lastLocal.current = local;
    lastRemote.current = remote;

    const newMerged = [];
    const newMergedIdsOrdered = [];
    const newMergedIds = new Set();

    local?.forEach((localItem) => {
      newMerged.push(localItem);
      newMergedIdsOrdered.push(localItem[identity]);
      newMergedIds.add(localItem[identity]);
    });

    remote?.forEach((remoteItem) => {
      if (!newMergedIds.has(remoteItem[identity])) {
        newMerged.push(remoteItem);
        newMergedIdsOrdered.push(remoteItem[identity]);
      }
    });
    if (!arrayUtils.shallowEquals(newMergedIdsOrdered, mergedIds.current)) {
      mergedIds.current = newMergedIdsOrdered;
      merged.current = newMerged;
    }

    return merged.current;
  }, [identity]);
};
