import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import * as objUtils from 'utils/object';
import { Action } from 'utils/action';
import { textFieldTypes } from 'form/utils';
import { Form } from 'form/form';
import { useFormProps } from 'form/use_form_props';
import TextField from 'form/text_field';
import Submit from 'form/submit';
import './miniform_text_field.sass';

/**
 * A self-submitting text input. This class can be connected to redux like this:
 * <MiniformTextField action={someAction}/>
 *
 * or it can be left unconnected:
 * <MiniformTextField submit={onSubmit}/>
 *
 * @param {Object} props
 * @returns {React.element}
 * @constructor
 */
export const MiniformTextField = props => {
  const [formProps] = useFormProps(props.action, objUtils.except(props, Object.keys(localProps)));

  return <Form {...formProps} className={makeClassName(['miniform-text', props.className])}>
    {form => <Fragment>
      <TextField
        enclosed
        required={props.required}
        requiredNoStar={props.requiredNoStar}
        label={props.label}
        errorLabel={props.errorLabel}
        type={props.type}
        {...form.propsForFieldGroup(props.name)}
      />
      <Submit
        type="min"
        mobileSize="auto"
        className="miniform-text-field__button"
        loading={form.isSubmitting}
      >
        {props.buttonText}
      </Submit>
    </Fragment>}
  </Form>;
};

const localProps = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(textFieldTypes),
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  action: PropTypes.instanceOf(Action),
  required: PropTypes.bool,
  requiredNoStar: PropTypes.bool
};

MiniformTextField.defaultProps = {
  required: true,
  requiredNoStar: false
};

MiniformTextField.propTypes = {
  ...Form.propTypes,
  ...localProps,
  children: PropTypes.any
};
