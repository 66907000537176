import React from "react";
import { FormattedMessage } from "react-intl";

/**
 * Parses the full name to display.
 * Note that this is currently only based on western standards.
 *
 * TODO: Improve this to actually localize the full name
 *       based on the cultural origin.
 *
 * Usage:
 *
 * studentName('Antonio', 'Margheriti');
 * Returns: "Antonio Margheriti"
 *
 * studentName('', 'Margheriti');
 * Returns: "Margheriti"
 *
 * studentName(undefined, 'Margheriti');
 * Returns: "Margheriti"
 *
 * studentName(undefined, undefined);
 * Returns: ""
 *
 * @param {String} firstName
 * @param {String} lastName
 * @returns {String}
 */
export const constructName = (firstName, lastName) => (
  [firstName, lastName].filter(s => s).join(' ')
);


/**
 * Choose between the plural and singular version of a word.
 *
 * @param {int} count - The number of items.
 * @param {String} singular - The singular version
 * @param {String=} plural - The plural version. This is an optional parameter, but it is
 *   recommended. Omitting it results in appending an 's,' which is English-specific and only
 *   works with a fraction of words.
 * @param {Object=} opts - Options
 *   @param {Boolean=false} opts.delimit - Localize the number with commas, dots etc.
 *   @param {Boolean=false} opts.textOnly - Don't output the number -- just the text.
 * @returns {String}
 */
export const pluralize = (count, singular, plural, opts = {}) => {
  const pluralized = (count === 1) ? singular : (plural || `${singular}s`);

  if (opts.textOnly) {
    return pluralized;
  } else {
    const formattedCount = opts.delimit ? Number(count).toLocaleString() : count;
    return `${formattedCount} ${pluralized}`;
  }
};


/**
 * Function to apply css classes to FormattedMessage.
 *
 * @param {String} id - id of the FormattedMessage
 * @param {String} defaultMessage - defaultMessage of the FormattedMessage
 * @param {String} className - name of the classname to apply
 * @returns {FormattedMessage}
 */

export const formattedMessageWithClass = (id, defaultMessage, className) => (
  <FormattedMessage id={id} defaultMessage={defaultMessage}>
    {(txt) => (
      <span className={className}>
        {txt}
      </span>
    )}
  </FormattedMessage>
);
